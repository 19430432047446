@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .text-gradient {
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
